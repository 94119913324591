import React, { useState } from 'react';
import FileExplorer from './FileExplorer';
import axios from 'axios';
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
const Home = () => {
    const token = localStorage.getItem('token');
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [bulk, setBulk] = useState('');
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    const handleUpload = async () => {
        if (!file) {
            setMessage('Please select a file.');
            return;
        }
        const formData = new FormData();
        formData.append('zipFile', file);
        formData.append('bucket', bulk);
        try {
            const response = await axios.post('/api/upload', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });
            setMessage(response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
            setMessage('Error uploading file.');
        }
    };
    const selectBulk = (event) => {
        setBulk(event.target.value);
    }
    return (<Container>
        <h1 className='text-center'>CDN UPLOADS</h1>
        <Row>
            <Col md={8}>
                <Form.Select aria-label="select-bulk" onChange={selectBulk} value={bulk} size="md">
                    <option value="">Select Project</option>
                    <option value="mondedessin">Monde dessin FR</option>
                    <option value="mehndidesignworld">Mehndi design world</option>
                    <option value="mehndiworld">Mehndi world In</option>
                </Form.Select>
            </Col>
            <Col md={4}>
                {bulk && (
                    <>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        <Button variant="primary" size='md' onClick={handleUpload}>Upload</Button>
                        {message && (<p>{message}</p>)}
                    </>
                )}
            </Col>
            <Col sm={12}>
                {bulk && (<FileExplorer bulket={bulk} />)}
            </Col>
        </Row>
    </Container>);
};

export default Home;
