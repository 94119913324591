import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/authService';
import { Button, Form, FormControl, Row, Col, Container } from 'react-bootstrap';
import { isAuthenticated } from '../utils/auth';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated()) {
            navigate('/');
        }
    }, [navigate]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login(username, password);
            setMessage('');
        } catch (err) {
            setMessage('Error logging in');
        }
    };

    return (
        <Container className='mt-3'>
            <Row className='justify-content-center'>
                <Col md="4">
                    <Form onSubmit={handleSubmit}>
                        <h2>Login</h2>
                        <FormControl
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className='mb-2'
                        />
                        <FormControl
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className='mb-2'
                        />
                        <div>
                            <span className='text-danger'>{message}</span>
                        </div>
                        <Button variant="success" size="md" type="submit">Login</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
