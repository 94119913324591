import React, { useState, useEffect } from 'react';
import File from './File';
import Folder from './Folder';
import { Card } from 'react-bootstrap';

const FolderComponent = ({ folder, prevDir, bulket }) => {
  const [endpoint, setEndpoint] = useState('');
  useEffect(() => {
    if (bulket === 'mondedessin') {
      setEndpoint("https://cdn.mondedessin.fr/");
    } else if (bulket === 'mehndidesignworld') {
      setEndpoint("https://cdn.mehndidesignworld.com/");
    } else if (bulket === 'mehndiworld') {
      setEndpoint("https://cdn.mehndiworld.in/")
    }
  }, [bulket]);
  const [isOpen, setIsOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = () => {
    let textToCopy = '';
    {
      folder.contents.map((item, index) => {
        if (item.type === 'file') {
          let src = (prevDir) ? endpoint + prevDir + item.name : endpoint + folder.name + '/' + item.name;
          textToCopy += src + '\n';
        }
      })
    }
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopySuccess('Copied!');
    }).catch(err => {
      setCopySuccess('Failed to copy!');
      console.error('Could not copy text: ', err);
    });
    setTimeout(() => {
      setCopySuccess('');
    }, 3000);
  };
  return (
    <div className="folder">
      <Card className={isOpen ? 'is-active' : ''}>
        <Card.Header onClick={() => setIsOpen(!isOpen)}>
          <Card.Title>{folder.name}</Card.Title>
          <svg onClick={() => copyToClipboard()} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" title="copy all link">
            <path d="M20.9983 10C20.9862 7.82497 20.8897 6.64706 20.1213 5.87868C19.2426 5 17.8284 5 15 5H12C9.17157 5 7.75736 5 6.87868 5.87868C6 6.75736 6 8.17157 6 11V16C6 18.8284 6 20.2426 6.87868 21.1213C7.75736 22 9.17157 22 12 22H15C17.8284 22 19.2426 22 20.1213 21.1213C21 20.2426 21 18.8284 21 16V15" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
            <path d="M3 10V16C3 17.6569 4.34315 19 6 19M18 5C18 3.34315 16.6569 2 15 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3.51839 3.82475 3.22937 4.69989 3.10149 6" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          <span>{copySuccess}</span>
        </Card.Header>
        {isOpen && (
          <Card.Body>
            {folder.contents.map((item, index) => {
              if (item.type === 'folder') {
                let dir = '';
                if (prevDir != undefined) {
                  dir = prevDir;
                } else {
                  dir = folder.name + '/';
                }
                return <Folder key={index} folder={item} prevDir={dir + item.name + '/'} bulket={bulket} />;
              } else {
                let src = (prevDir) ? endpoint + prevDir + item.name : endpoint + folder.name + '/' + item.name;
                return <File key={index} file={item} src={src} bulket={bulket} />;
              }
            })}
          </Card.Body>

        )}
      </Card>
    </div>
  );
};

export default FolderComponent;
