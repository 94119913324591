import React from 'react';
import { Col, Image, ListGroup, Row } from 'react-bootstrap';

const formatSize = (sizeInBytes) => {
    const sizeInMB = sizeInBytes / (1024 * 1024);
    return `${sizeInMB.toFixed(2)} MB`;
};
const isImageFile = (fileName) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
    const extension = fileName.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
};
const File = ({ file, src }) => {
    return (
        ((file.name && isImageFile(file.name)) && (
            <ListGroup>
                <ListGroup.Item>
                    <Row>
                        <Col lg={8} md={7} xs={6}><a href={src} target='_blank'>{file.name}</a></Col>
                        <Col>{formatSize(file.size)}</Col>
                    </Row>
                </ListGroup.Item>
            </ListGroup>
        ))
    );
};

export default File;
