import axios from 'axios';

export const login = async (username, password) => {
    const { data } = await axios.post(`/api/login`, { username, password });
    if (data.token) {
        localStorage.setItem('token', data.token);
        window.location.href = '/';
    }
    return data;
};
