import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Folder from './Folder';
import { Button, Container } from 'react-bootstrap';

const FileExplorer = ({ bulket }) => {
    const token = localStorage.getItem('token');
    const [isLoading, setLoading] = useState(false);
    const [folders, setFolders] = useState([]);
    const [nextMarker, setNextMarker] = useState('');
    const [isTruncated, setIsTruncated] = useState(false);
    useEffect(() => {
        fetchFiles('');
        setFolders([]);
    }, [bulket]);
    const fetchFiles = (marker) => {
        setLoading(true);
        axios.get(`/api/files?marker=${marker}&bucketName=${bulket}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(response => {
                setFolders(prev => [...prev, ...response.data.folderStructure]);
                setIsTruncated(response.data.isTruncated);
                setNextMarker(response.data.nextMarker);
            })
            .catch(error => console.error('Error fetching files:', error))
            .finally(() => setLoading(false));
    };
    const handleLoadMore = () => {
        fetchFiles(nextMarker);
    };
    return (
        <Container fluid>
            {isLoading && (<span>Loading...</span>)}
            {bulket && (
                <div className="file-explorer">
                    {folders.map((folder, index) => (
                        <Folder key={index} folder={folder} bulket={bulket} />
                    ))}
                    {isTruncated && (
                        <Button variant="secondary" onClick={handleLoadMore} className="load-more-button" disabled={isLoading}>{isLoading ? 'Loading...' : 'Load More'}</Button>
                    )}
                </div>
            )}
        </Container>
    );
};

export default FileExplorer;
